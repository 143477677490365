import {HROC_CHECKLIST_KIND_TRANSLATION_KEYS} from '@hconnect/common/consts'
import type {TaskChecklistsOption} from '@hconnect/common/types'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from '@mui/icons-material'
import {
  Autocomplete,
  CircularProgress,
  Paper,
  TextField,
  MenuItem,
  Checkbox,
  Typography
} from '@mui/material'
import {isEqual} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useChecklistTemplates} from '../../hooks/api/useChecklistTemplates'

export type Props = {
  onChange: (checklists: TaskChecklistsOption[]) => void
  value: TaskChecklistsOption[]
  errorText?: React.ReactNode
  required?: boolean
  disabled?: boolean
}

const paper = (props) => <Paper elevation={8} {...props} />

export const TaskChecklistsField: React.FC<Props> = ({
  required = false,
  onChange,
  value,
  disabled,
  errorText
}) => {
  const {data: checklists = [], isFetching} = useChecklistTemplates()
  const {t} = useTranslation()
  const getOptionLabel = (option: TaskChecklistsOption) =>
    t(`shiftEvent.${HROC_CHECKLIST_KIND_TRANSLATION_KEYS[option.kind]}`)

  return (
    <Autocomplete
      ChipProps={{
        sx: {
          borderRadius: 1,
          marginRight: 1
        },
        variant: 'outlined',
        size: 'small'
      }}
      data-test-id="event-form-safetyChecklists"
      value={value}
      disableClearable
      onChange={(_, newValue) => {
        if (newValue?.length === value?.length || isEqual(newValue, value)) return
        else onChange(newValue as TaskChecklistsOption[])
      }}
      options={checklists}
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!errorText}
            helperText={errorText}
            required={required}
            label={`${t('shiftEvent.label.safetyChecklists')} (${t('shiftEvent.label.optional')})`}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )
      }}
      PaperComponent={paper}
      forcePopupIcon={false}
      disabled={disabled}
      multiple
      renderOption={renderOption}
    />
  )
}

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TaskChecklistsOption,
  {selected}: {selected: boolean},
  {getOptionLabel}: {getOptionLabel: (option: TaskChecklistsOption) => string}
) => (
  <MenuItem {...props} data-test-id={`option-label-${option.id}`}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      style={{marginRight: 8}}
      checked={selected}
    />
    <Typography variant="subtitle1">{getOptionLabel(option)}</Typography>
  </MenuItem>
)
