import {EventAttachmentUpload} from '@hconnect/common/components/attachments/EventAttachmentUpload'
import {ContentBox} from '@hconnect/common/components/ContentBox'
import {MAX_ALLOWED_ATTACHMENTS, SIDE_CARD_MIN_HEIGHT_IN_PX} from '@hconnect/common/consts'
import {AttachmentFile} from '@hconnect/common/types'
import {
  getNewlyAttachedFiles,
  getUrlsForRemovedImages,
  attachmentToFile,
  getUniqueAttachments
} from '@hconnect/common/utils'
import {LoadingSwitcher} from '@hconnect/uikit/src/lib2'
import {Close} from '@mui/icons-material'
import IconWarning from '@mui/icons-material/Warning'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {AxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'

import {isTask} from '../../common/utils/eventType'
import {useCreateNonRecToRecEvent} from '../../hooks/api/useEventCreate'
import {QueryKey as GetEventsQueryKey} from '../../hooks/api/useEvents'
import {QueryKey as SearchEventsQueryKey} from '../../hooks/api/useEventsSearch'
import {useEventUpdate} from '../../hooks/api/useEventUpdate'
import {useFeatureFlag} from '../../hooks/useFeatureFlag'
import {useHasPermission} from '../../hooks/usePermission'
import {ChecklistsInputSettings} from '../../types/shareEventForm.types'
import {
  ShiftEvent,
  EventUpdate as UpdateEventDto,
  EventEditModeEnum,
  EventEditModes
} from '../../types/shiftHandover.types'
import {getFormPrimaryButtonSx} from '../../utils'
import {HttpErrorHandlingComponentProps, withHttpErrorHandler} from '../common/GlobalErrorBoundary'

import {EventFormContainer} from './EventFormContainer'

interface Props extends HttpErrorHandlingComponentProps {
  item: ShiftEvent
  doClose(original: ShiftEvent): void
  onSubmit(item: ShiftEvent): void
  checklistsInputSettings?: ChecklistsInputSettings
  onlyEditSeries?: boolean
}

export const EventEdit = withHttpErrorHandler<Props>(
  ({item, doClose, onSubmit, onlyEditSeries, checklistsInputSettings}) => {
    const [uniqueAttachments, setUniqueAttachments] = useState(
      getUniqueAttachments(item?.attachments, MAX_ALLOWED_ATTACHMENTS)
    )

    useEffect(() => {
      setUniqueAttachments(getUniqueAttachments(item?.attachments, MAX_ALLOWED_ATTACHMENTS))
    }, [item?.attachments])

    const files = (uniqueAttachments || []).map((attachment) => attachmentToFile(attachment))

    return files.every(({preview}) => !!preview) ? (
      <EventEditComponent
        item={item}
        filesWithPreview={files}
        doClose={doClose}
        onSubmit={onSubmit}
        onlyEditSeries={onlyEditSeries}
        checklistsInputSettings={checklistsInputSettings}
      />
    ) : (
      <ContentBox
        data-test-id="sideCard-spinner"
        isLoading
        minHeight={SIDE_CARD_MIN_HEIGHT_IN_PX}
        mode="max100PercentOfParentHeight"
      />
    )
  }
)

export const EventEditComponent = withHttpErrorHandler<
  Props & {filesWithPreview: Array<AttachmentFile>}
>(({item, doClose, onSubmit, filesWithPreview, onlyEditSeries, checklistsInputSettings}) => {
  const {t} = useTranslation()
  const hasPermission = useHasPermission()
  const isMovieAttachmentEnabled = useFeatureFlag('movieAttachment')
  const queryClient = useQueryClient()
  const createTaskSchedule = useCreateNonRecToRecEvent()
  const updateAction = useEventUpdate()
  const [files, setFiles] = useState<Array<AttachmentFile>>(filesWithPreview)

  const [conflictError, setConflictError] = useState<AxiosError | undefined>(undefined)
  const [editMode, setEditMode] = useState<EventEditModes | undefined>(
    onlyEditSeries ? EventEditModeEnum.SERIES : EventEditModeEnum.SINGLE
  )

  const data = updateAction.data ?? createTaskSchedule.data
  const updateError = updateAction.error
  const originalAttachments = item.attachments ?? []
  const isLoading = updateAction.isLoading || createTaskSchedule.isLoading

  useEffect(() => {
    if (data) {
      onSubmit(data)
    } else if (updateError) {
      if (updateError.response?.status === 409) {
        setConflictError(updateError)
      } else {
        throw updateError
      }
    }
  }, [data, updateError, onSubmit])

  const clearConflictError = React.useCallback(() => {
    setConflictError(undefined)
    void queryClient.invalidateQueries([GetEventsQueryKey, SearchEventsQueryKey])
    doClose(item)
  }, [setConflictError, queryClient, doClose, item])

  const doSubmit = (nextItem: UpdateEventDto) => {
    const next: UpdateEventDto = {
      ...nextItem,
      // attachment specific
      attachmentsToRemove: getUrlsForRemovedImages(originalAttachments, files),
      newAttachments: getNewlyAttachedFiles(originalAttachments, files)
    }

    // special case were an existing non-repetitive Task is turned into a repetitive Task
    if (isTask(item) && isTask(next) && !item.repetitionInfo && !!next.repetitionInfo) {
      // collision detection on the schedule is ignored for now
      createTaskSchedule.mutate(next)
    } else {
      updateAction.mutate({
        event: next,
        scheduleRecurringTask: editMode === EventEditModeEnum.SERIES
      })
    }
  }

  return (
    <LoadingSwitcher isLoading={isLoading}>
      <Dialog
        maxWidth="xs"
        data-test-id="error-conflict-modal"
        onClose={clearConflictError}
        open={conflictError !== undefined}
      >
        <DialogTitle>
          <IconWarning color={'error'} />
          &nbsp; {t('shiftEvent.error.conflictHeading')}
        </DialogTitle>
        <DialogContent>{t('shiftEvent.error.conflictBody')}</DialogContent>
        <DialogActions>
          <Button onClick={clearConflictError} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {!onlyEditSeries && (
        <EventEditDialog
          isRepeating={'repetitionInfo' in item && (item.repetitionInfo?.enabled ?? false)}
          setEditMode={setEditMode}
          doClose={() => doClose(item)}
        />
      )}
      <EventFormContainer
        item={item}
        doClose={() => doClose(item)}
        doSubmit={doSubmit}
        editMode={editMode}
        checklistsInputSettings={checklistsInputSettings}
      >
        <EventAttachmentUpload
          setAttachments={setFiles}
          attachments={files}
          isMovieAttachmentEnabled={isMovieAttachmentEnabled}
          hasPermission={hasPermission}
        />
      </EventFormContainer>
    </LoadingSwitcher>
  )
})

type EventEditDialogProps = {
  setEditMode: (mode: EventEditModes) => void
  isRepeating: boolean
  doClose: () => void
}

const EventEditDialog: React.FC<EventEditDialogProps> = (props) => {
  const {setEditMode, isRepeating, doClose} = props
  const [editModeDialog, openEditModeDialog] = useState<boolean>(isRepeating)
  const {t} = useTranslation()
  return (
    <Dialog data-test-id="event-edit-selection" open={editModeDialog}>
      <DialogTitle>{t('shiftEvent.recurringTask.editRecurringTask')}</DialogTitle>
      <DialogActions>
        <Button
          startIcon={<Close />}
          onClick={() => {
            doClose()
            openEditModeDialog(false)
          }}
          color="primary"
          variant="text"
          data-test-id="edit-cancel"
        >
          {t('action.cancel')}
        </Button>
        <Button
          onClick={() => {
            setEditMode(EventEditModeEnum.SINGLE_IN_SERIES)
            openEditModeDialog(false)
          }}
          color="secondary"
          variant="text"
          sx={getFormPrimaryButtonSx}
          data-test-id="edit-single"
        >
          {t('shiftEvent.recurringTask.editEvent')}
        </Button>
        <Button
          onClick={() => {
            setEditMode(EventEditModeEnum.SERIES)
            openEditModeDialog(false)
          }}
          color="secondary"
          variant="text"
          sx={getFormPrimaryButtonSx}
          data-test-id="edit-series"
        >
          {t('shiftEvent.recurringTask.editSeries')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
