import {type ChecklistTemplateDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const QueryKey = 'checklist-templates'

export const useChecklistTemplates = (
  options?: UseQueryOptions<ChecklistTemplateDto[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/hroc-checklists/api/${plantId}/checklist-templates`

  return useQuery<ChecklistTemplateDto[], AxiosError>(
    [QueryKey],
    async () => {
      const response = await axiosInstance.get<ChecklistTemplateDto[]>(path)
      return response.data
    },
    options
  )
}
