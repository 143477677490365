import {type TaskChecklist} from '@hconnect/common/types'
import {type AxiosError} from 'axios'
import {useQuery, type UseQueryOptions} from 'react-query'

import {useConfig} from '../useConfig'

import {useApi} from './useApi'

export const TaskChecklistsQueryKey = 'task-checklists'

export const useTaskChecklists = (
  taskId: string | undefined,
  options?: UseQueryOptions<TaskChecklist[], AxiosError>
) => {
  const {axiosInstance} = useApi()
  const {plantId} = useConfig()
  const path = `/hroc-checklists/api/${plantId}/checklists/for-task/${taskId}`

  return useQuery<TaskChecklist[], AxiosError>(
    [TaskChecklistsQueryKey, taskId],
    async () => {
      const response = await axiosInstance.get<TaskChecklist[]>(path)
      return response.data
    },
    options
  )
}
